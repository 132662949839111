import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom'
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import TableComponent from '../TableComponent'
import client from '../../../lib/feathers'
import "./AdminVolcanes.css";



const AdminVolcanes = ({history}) => {
    const [volcanes, setVolcanes] = useState({data: []})
    const [alert, setAlert] = useState('')

    const getVolcanes = async () => {
        console.log('Getting volcanes...')
        const service = client.service('volcanes')

        const vList = await service.find({
            query: { $limit: 9999 }
          })
        console.log('Got volcanes:', vList)
        setVolcanes({data: vList.data})
    }

    useEffect( () => {
        getVolcanes()
    }, [])
    
    const columns = [{ dataField: 'id',        text: 'id',    style: { width: "2rem"}, headerStyle: { width: '2rem' }, },
                     { dataField: 'NoVolcan',  text: 'NoVolcan',    Astyle: { width: "6rem"}, AheaderStyle: { width: '6rem' } },
                     { dataField: 'Nombre',    text: 'Nombre' },
                     { dataField: 'Region',    text: 'Region'  },
                     { dataField: 'Type',      text: 'Type'    },
                     { dataField: 'Lat',       text: 'Lat', Astyle: { width: "3rem"}, AheaderStyle: { width: '3rem' }      },
                     { dataField: 'Lon',       text: 'Lon', Astyle: { width: "3rem"}, AheaderStyle: { width: '3rem' }  },
                     { dataField: 'enabled',   text: 'Usar?', Astyle: { width: "4rem"}, AheaderStyle: { width: '4rem' } },    
                    { 
                        isDummyField: true,   
                        text: '',       
                        style: { width: "6rem"}, 
                        headerStyle: { width: '6rem' },
                        align: 'right',
                        formatter: (cellContent, row) => (<div>
                            <span className="fa fa-pencil table-icon" onClick={() => history.push('/admin/volcanes/' + row.id)}></span>
                            <span className="fa fa-trash table-icon" onClick={() => confirmDelete(row.id)}></span>
                            <a target="_blank" href={row.link}><span className="fa fa-external-link-square table-icon"></span></a>
                        </div>)
                    }                 
                    ]
    const indication = () => {
        return 'Tabla vacia'
    }
    
    const confirmDelete = id => {
        setAlert(
            <Alert variant="danger" onClose={() => setAlert('')} dismissible>
                ¿Está seguro/a de que quiere borrar el volcan {id}?
                <Button className="button-confirmar-borrar-usuario" variant="danger" onClick={() => doDeleteVolcan(id)}>Confirmar</Button>
            </Alert>
        )
    }

    const doDeleteVolcan = async (id) => {
        console.log('Deleting id', id, 'after confirmation')
        try {
            await client.service('volcanes').remove(id)
            setAlert('')
        } catch(e) {
            console.log(e)
            setAlert(
                <Alert variant="danger" onClose={() => setAlert('')} dismissible>
                    Hubo un error al borrar el volcan con ID {id}: {e.message}
                </Alert>
            )
        }
        getVolcanes()
    }

    return (
        <Container fluid={true}>
            <h1>Administrar volcanes</h1>
            {alert}
            <Button className="table-new-button" 
                    onClick={() => history.push('/admin/volcanes/new')}>
                    Crear Nuevo
                </Button>
            <Row>
            <Col>
            {
                volcanes.data.length > 0 ?
                    <TableComponent 
                        data={ volcanes.data } 
                        columns={ columns } 
                        noDataIndication={ indication }
                        keyField='id' 
                        pagination={ paginationFactory() }
                    /> : <p> {indication()}</p>
            }
            </Col>
            </Row>
        </Container>
    )
};

export default withRouter(AdminVolcanes);
