import React, { useState } from 'react'

import Alert from 'react-bootstrap/Alert'
import 'bootstrap/dist/css/bootstrap.min.css';
import client from '../../../../lib/feathers'
import { validate, createFormFromSchema } from '../../../../lib/myForm'
import { validations } from './validations'

const NewVolcanComponent = () => {
    const [formData, setFormData] = useState({errors: [], success: []});

    const handleSubmit = async e => {
        e.preventDefault()
        const errors = validate(formData, validations)
        setFormData({...FormData, errors})
        if (errors.length === 0) {
            // OK!
            setFormData({...FormData, errors: [], success: ['Validacion ok'] })
            console.log('Validation ok for', formData)
            const { NoVolcan, Nombre, Region, Type, Lat, Lon, link, text } = formData
            const enabled = formData.enabled === 'on' ? 1 : 0
            try {
                await client.service('volcanes').create({ NoVolcan, Nombre, Region, Type, Lat, Lon, link, text, enabled })
                setFormData({...formData, success: formData.success.concat(['Nuevo volcan ' + NoVolcan + ' creado']), errors: []})
            } catch(e) {
                console.log('Error while registering:', e)
                setFormData({...formData, errors: ['Error al crear el nuevo volcan']})
            } 
        }
    }

    const handleChange = name => e => {
        console.log(name, e.target.value)
        setFormData({...formData, [name]: e.target.value})
    }

    let alert = ''
    if (formData.errors && formData.errors.length > 0) {
        alert = (
        <Alert variant="danger" onClose={() => setFormData({...formData, errors: []})} dismissible>
          {formData.errors.map((error, i) => <p key={i}>{error}</p>)}
        </Alert>
        )
    }
    let success = ''
    if (formData.success && formData.success.length > 0) {
        success = (
            <Alert variant="success" onClick={() => setFormData({...formData, success: []})} dismissible>
                {formData.success.map((success, i) => <p key={i}>{success}</p>)}
            </Alert>
        )
    }

    // name , label, type, onChange, placeholder
    const formSchema = [
        ['NoVolcan', undefined,          'input',    handleChange('NoVolcan'), 'El codigo del volcan. Ej: 1243-22-'],
        ['Nombre',   undefined,          'input',    handleChange('Nombre'),   'Ej. Payun Matru'],
        ['Region',   undefined,          'input',    handleChange('Region'),   'Ej. Argentina'],
        ['Type',     undefined,          'input',    handleChange('Type'),     'Ej. Shield Volcano'],
        ['Lat',      undefined,          'input',    handleChange('Lat'),      ''],
        ['Lon',      undefined,          'input',    handleChange('Lon'),      ''],
        ['link',     'Link',             'input',    handleChange('link'),     ''],
        ['enabled',  'Mostrar en mapa?', 'checkbox', handleChange('enabled')             ],
        ['text',     'Descripcion',      'input',    handleChange('text'),     'Notas...'],
        ['',         'Agregar Volcan',   'submit',   handleSubmit                        ],
    ]
 
    return(
        <div>
            {alert}{success}
            {createFormFromSchema(formSchema)}
        </div>
    )
}

export default NewVolcanComponent