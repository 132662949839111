import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const TableComponent = props => {
    return (
        <BootstrapTable 
            {...props}
            striped
            hover
            condensed
            bootstrap4
        />
    )
}

export default TableComponent