function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
   
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
   
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}



// if (!('PushManager' in window)) {
//   // Push isn't supported on this browser, disable or hide UI.
//   return;
// }

// Register the serviceWorker - returns registration object
// function registerServiceWorker() {
//   return navigator.serviceWorker.register('/worker.js')
//   .then(function(registration) {
//     console.log('Service worker successfully registered.');
//     return registration;
//   })
//   .catch(function(err) {
//     console.error('Unable to register service worker.', err);
//   });
// }

// // Request Permission
// function askPermission() {
//   return new Promise(function(resolve, reject) {
//     const permissionResult = Notification.requestPermission(function(result) {
//       resolve(result);
//     });

//     if (permissionResult) {
//       permissionResult.then(resolve, reject);
//     }
//   })
//   .then(function(permissionResult) {
//     if (permissionResult !== 'granted') {
//       throw new Error('We weren\'t granted permission.');
//     }
//   });
// }

const getSubscription = async () => {
    if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        return;
    }
    navigator.serviceWorker.register('/worker.js');
    const registration = await navigator.serviceWorker.ready
    // console.log({registration})

    let subscription = await registration.pushManager.getSubscription()
    // console.log({subscription})

    // if (subscription) {
    //     return subscription;
    // }
    
    const vapidPublicKey = 'BCR8msXQ-6pEROebYEeVinhRaWupKoKCfRuKxH8wcRu1tOg9wUYSGHs7gQhwyDlnyUlOGnX7kI82yh8MSntvrBo'
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
    
    // console.log('index.js: no subscription. Subscribe!')
    
    subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey
    });
    
    if (subscription) {
        fetch('/api/register', {
            method: 'post',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                subscription: subscription
            }),
        });
    }
    return subscription
}

// const subscription = getSubscription()
// console.log({subscription})


    // const payload = 'Im the payload'
    // const delay = 5
    // const ttl = 200

    // fetch('/api/sendNotification', {
    //   method: 'post',
    //   headers: {
    //     'Content-type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     subscription: subscription,
    //     payload: payload,
    //     delay: delay,
    //     ttl: ttl,
    //   }),
    // });



export default getSubscription