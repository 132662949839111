import io from 'socket.io-client';
import feathers from '@feathersjs/client';
// import rest from '@feathersjs/rest-client'

const socket = io('');
const client = feathers();


// Connect to the same as the browser URL (only in the browser)
// const restClient = rest('/api/');
// Configure an AJAX library (see below) with that client 
// client.configure(restClient.fetch(window.fetch));
client.configure(feathers.socketio(socket));

// Defaults to localStorage, see https://docs.feathersjs.com/api/authentication/client.html#configuration
client.configure(feathers.authentication());


export default client;
