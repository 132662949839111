import React, {useState} from "react";
import { withRouter } from "react-router"
import UserContext from '../../lib/UserContext'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import client from '../../lib/feathers'
import 'bootstrap/dist/css/bootstrap.min.css';
import {required, isEmail, isBetween, validate} from '../../lib/myForm'

const RegisterComponent = ({history}) => {
    const [formData, setFormData] = useState({errors: []});

    const doSubmit = async formData => {
        await client.logout();
        console.log('Logged out')
        const {email, password} = formData

        try {
          return await client.authenticate({
            strategy: 'local',
            email, password
          })
        } catch(e) {
          console.log('Error while logging in:', e)
          setFormData({...formData, errors: ['Error al iniciar sesión:', e.message]})
        }
    }

    const handleSubmit = context => async e => {
        e.preventDefault()
        const errors = validate(formData, validations)
        if (errors.length > 0) {
            setFormData({...formData, errors})
        } else {
            const auth = await doSubmit(formData)
            if (auth !== undefined) {
                console.log('auth es', auth)
                context.setAuth(auth.user)
                history.push('/')
            }
            
        }
    }
    
    const handleChange = name => e => {
        console.log(name, e.target.value)
        setFormData({...formData, [name]: e.target.value})
    }

    // Each field has a list of tests. Each test is a list of two items:
    // The test itself (as a function) and the error text or a function that returns the error text.
    const validations = {
        email: [
            [required, 'La dirección de correo es obligatoria'],
            [isEmail,  string => string + ' no parece una dirección de correo']
        ],
        password: [
            [required, 'La contraseña es obligatoria'],
            [isBetween(2,60),  'La contraseña debe tener entre 2 y 60 caracteres']
        ],
    }


    return(
        <UserContext.Consumer>
        {
          context => { 
            let alert = ''
            if (formData.errors.length > 0) {
                alert = (
                <Alert variant="danger" onClose={() => setFormData({...formData, errors: []})} dismissible>
                  {formData.errors.map((error, i) => <p key={i}>{error}</p>)}
                </Alert>
                )
            }
            
            return(
            <div>
                {alert}
                <Form onSubmit={handleSubmit(context)}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Dirección de email</Form.Label>
                        <Form.Control type="email" name="email" onChange={handleChange('email')}
                        placeholder="Enter email" />
                    </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                    type="password" 
                    name="password"
                    onChange={handleChange('password')}
                    placeholder="Password" />
              </Form.Group>

              <Button variant="primary" type="submit">Iniciar Sesión</Button>
              </Form>
            </div>)}
      }
    </UserContext.Consumer>
    )
}

export default withRouter(RegisterComponent);

