import React, { useState } from "react";
import { withRouter } from "react-router"

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import 'bootstrap/dist/css/bootstrap.min.css';
import "./Admin.css";

const Admin = ({history}) => {
  const [action, setAction] = useState('login');
  return (
    <Container>
        <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} >
        <Card>
            <Card.Header>Usuarios</Card.Header>
            <Card.Body>
                <p>Administrar usuarios, incluido quien es administrador.</p>
                <Button onClick={() => history.push('/admin/users')}>Usuarios</Button>
            </Card.Body>
        </Card>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} >
        <Card>
            <Card.Header>Volcanes</Card.Header>
            <Card.Body>
                <p>Administrar la lista de volcanes, incluidos los links que aparecen en el popup.</p>
                <Button onClick={() => history.push('/admin/volcanes')}>Volcanes</Button>
            </Card.Body>
        </Card>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} >
        <Card>
            <Card.Header>Backup</Card.Header>
            <Card.Body>
                <p>Bajar un backup en CSV de todos los datos.</p>
                <Button>Bajar datos</Button>
            </Card.Body>
        </Card>
        </Col>
      </Row>
    </Container>

  );
};

export default withRouter(Admin);
