import React from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines';


const SparklineComponent = ({past, color}) => {
    return (
        <Sparklines data={past} svgWidth={40} svgHeight={10} limit={6} margin={5}>
            <SparklinesLine color={color} />
        </Sparklines>
    )
}

export default SparklineComponent