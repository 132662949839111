import React from 'react';
import './LastChanges.css'
import Card from 'react-bootstrap/Card'


const LastChanges = ({messages}) => {
    const colores = ['verde', 'amarillo', 'rojo']
    return (
        <Card>
            <Card.Header>Últimos avisos</Card.Header>
            <Card.Body>
                {messages.map((v,i) => {
                    const date = new Date(v.DateTime)
                    let mm = date.getMinutes()
                    mm = mm > 9 ? mm : '0' + mm
                    const hhmm = date.getHours() + ":" + mm  + "hs"
                    return(<div className="change-item" key={i}>
                        <span className="change-meta"> 
                            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()} - {hhmm}, {v.Region}
                        </span>
                        <span className="change-volcano-name">{v.Nombre}</span> pasó de {colores[v.last_cod_color]} a {colores[v.curr_cod_color]}
                    </div>
                    )
                })}
            </Card.Body>
        </Card>
    )
}

export default LastChanges;
