import React from 'react';
import './GeneralInfo.css'
import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.min.css';

const Contacto = () => {
    return (
      <Card>
          <Card.Header>Contacto</Card.Header>
          <Card.Body>
            <p>Daiana Baissac: dmbaissac@gmail.com</p>
            <p>Páginas de interés:</p>
            <ul>
                <li>https://www.argentina.gob.ar/defensa/citedef</li>
                <li>http://wwlln.net/</li>
                <li>http://wwlln.net/USGS/Global/</li>
            </ul>
          </Card.Body>
      </Card>
    )}

const GeneralInfo = () => {
    return (
        <Card>
            <Card.Header style={{fontWeight: "bold", color: "#404040"}}>Información general</Card.Header>
            <Card.Body>
                <p>
                Georayos - VolcanoAr es una aplicación Web que brinda información acerca de la
                actividad eléctrica detectada en cercanías de cada volcán por la <a href="http://wwlln.net/USGS/Global/">red WWLLN</a>.
                </p>

                <p>
                Cuando un volcán entra en erupción, emite a la atmósfera una cantidad de material
                particulado y gases (principalmente vapor de agua). Esta mezcla forma una pluma volcánica
                que puede alcanzar grandes alturas, dependiendo de la intensidad de la erupción y las
                condiciones atmosféricas en el lugar.
                </p>

                <p>
                La interacción de las partículas y los gases pueden generar descargas eléctricas (rayos y
                relámpagos) de distintas características en las diferentes partes de la pluma volcánica.
                Particularmente, cuando la pluma volcánica alcanza una altura considerable, la atmósfera se
                encuentra suficientemente fría como para permitir el crecimiento de cristales de hielo. Las
                descargas eléctricas que se pueden producir en estas condiciones son similares a las
                producidas durante una tormenta eléctrica meteorológica. Estas descargas eléctricas
                volcánicas, de gran intensidad, pueden ser registradas por las antenas diseñadas para detectar
                descargas atmosféricas de la red global WWLLN.
                </p>

                <p>
                En base a este fenómeno y dado que las descargas eléctricas volcánicas son similares a
                las descargas eléctricas atmosféricas, en VolcanoAr realizamos una clasificación de los volcanes
                según la probabilidad de que la electrificación registrada sea producto de una erupción
                volcánica explosiva. De esta manera tomamos las descargas eléctricas detectadas por
                la <a href="http://wwlln.net/USGS/Global/">WWLLN-Ash Cloud Monitor</a>, dentro de un radio de 20 km del
                centro de cada volcán y en un anillo de entre 20 y 100 km del centro del volcán, para los
                volcanes de mayor riesgo en el sector delimitado entre -10 a -90 latitud sur y -10 a – 90
                longitud oeste. Cada volcán se clasifica según la relación entre las descargas dentro del radio
                de 20 km y exteriores a este. Esta clasificación se expresa en una escala de tres colores: Rojo –
                Amarillo – Verde, correspondiéndose el rojo a las mayores posibilidades de que dicho volcán
                se encuentre en erupción y disminuyendo hacia e verde.
                </p>

                <h5>Contacto</h5>
                <p>Daiana Baissac: dmbaissac@gmail.com</p>

                <h5>Páginas de interés:</h5>
                <ul className="unindented-list">
                    <li><a href="https://www.argentina.gob.ar/defensa/citedef">https://www.argentina.gob.ar/defensa/citedef</a></li>
                    <li><a href="http://wwlln.net/">http://wwlln.net/</a></li>
                    <li><a href="http://wwlln.net/USGS/Global/">http://wwlln.net/USGS/Global/</a></li>
                </ul>
                
            </Card.Body>
        </Card>
    )
}

export default GeneralInfo;