import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import 'bootstrap/dist/css/bootstrap.min.css';
import client from '../../../../lib/feathers'
import { validate, createFormFromSchema } from '../../../../lib/myForm'
import { validations } from './validations'

const EditVolcanComponent = () => {
    const [formStatus, setFormStatus] = useState({errors: [], success: []});
    // Need a state for every field if I want to give it value={}. 
    // Else, react will complain about controlled and uncontrolled components
    const [NoVolcan , setNoVolcan]  = useState('')
    const [Nombre , setNombre]      = useState('')
    const [Region , setRegion]      = useState('')
    const [Type , setType]          = useState('')
    const [Lat , setLat]            = useState('')
    const [Lon , setLon]            = useState('')
    const [link , setLink]          = useState('')
    const [text , setText]          = useState('')
    const [enabled , setEnabled]    = useState(true)
    console.log('Rerun everything...')

    const { id } = useParams()

    useEffect( () => {
        const getVData = async () => {
            const vData = await client.service('volcanes').get(id)
            console.log({vData})
            setNoVolcan(vData.NoVolcan || '')
            setNombre(vData.Nombre || '')
            setRegion(vData.Region || '')
            setType(vData.Type || '')
            setLat(vData.Lat || '')
            setLon(vData.Lon || '')
            setLink(vData.link || '')
            setText(vData.text || '')
            if (vData.enabled === 1) {
                console.log('enabled is true')
                setEnabled(true)
            } else {
                console.log('enabled is false')
                setEnabled(false)
            }
        }
        getVData()
    }, [id])

    const handleSubmit = async e => {
        e.preventDefault()
        const formData = { NoVolcan, Nombre, Region, Type, Lat, Lon, link, text, enabled }
        const errors = validate(formData, validations)
        setFormStatus({ errors, success: []})
        if (errors.length === 0) {
            // OK!
            setFormStatus({errors: [], success: ['Validacion ok'] })
            console.log('Validation ok for', formData)
            formData.enabled = formData.enabled ? 1 : 0
            
            try {
                await client.service('volcanes').patch(id, formData)
                setFormStatus({ success: formStatus.success.concat(['Volcan ' + NoVolcan + ' actualizado']) })
            } catch(e) {
                console.log('Error while registering:', e)
                setFormStatus({ errors: formStatus.errors.concat(['Error al crear el nuevo volcan'])})
            }
        }
    }

    const handleChange = updateState => e => {
        console.log(e.target.value)
        updateState(e.target.value)
    }

    const handleCheckbox = e => setEnabled(!enabled)

    let alert = ''
    if (formStatus.errors && formStatus.errors.length > 0) {
        alert = (
        <Alert variant="danger" onClose={() => setFormStatus({})} dismissible>
          {formStatus.errors.map((error, i) => <p key={i}>{error}</p>)}
        </Alert>
        )
    }
    let success = ''
    if (formStatus.success && formStatus.success.length > 0) {
        success = (
            <Alert variant="success" onClick={() => setFormStatus({})} dismissible>
                {formStatus.success.map((success, i) => <p key={i}>{success}</p>)}
            </Alert>
        )
    }

    // name , label, type, onChange, placeholder, value
    const formSchema = [
        ['NoVolcan', undefined,          'input',    handleChange(setNoVolcan), 'El codigo del volcan. Ej: 1243-22-', NoVolcan],
        ['Nombre',   undefined,          'input',    handleChange(setNombre),   'Ej. Payun Matru',    Nombre],
        ['Region',   undefined,          'input',    handleChange(setRegion),   'Ej. Argentina',      Region],
        ['Type',     undefined,          'input',    handleChange(setType),     'Ej. Shield Volcano', Type],
        ['Lat',      undefined,          'input',    handleChange(setLat),      '',                   Lat],
        ['Lon',      undefined,          'input',    handleChange(setLon),      '',                   Lon],
        ['link',     'Link',             'input',    handleChange(setLink),     '',                   link],
        ['enabled',  'Mostrar en mapa?', 'checkbox', handleCheckbox,            '',                   enabled],
        ['text',     'Descripcion',      'input',    handleChange(setText),     'Notas...',           text],
        ['',         'Modificar Volcan', 'submit',   handleSubmit                         ],
    ]
 
    return(
        <div>
            {alert}{success}
            {createFormFromSchema(formSchema)}
        </div>
    )
}

export default EditVolcanComponent