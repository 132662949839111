import React, {useState} from "react";
import { withRouter } from "react-router"
import UserContext from '../../lib/UserContext'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDataAsCSV } from '../../lib/network'
import {required, validate} from '../../lib/myForm'

const SearchComponent = ({visibleRays, mapBounds, setIsSearching}) => {
    const [formData, setFormData] = useState({errors: []});

    const filterVolcanoes = (visibleRays, mapBounds) => {
        // Enviar solo los volcanes que están en el mapa. Los bordes del mapa
        // vienen en mapBounds
        let volcanoesList = []
        if (mapBounds !== undefined){
            const lat = [ mapBounds._northEast.lat , mapBounds._southWest.lat ]
            const lng = [ mapBounds._northEast.lng , mapBounds._southWest.lng ]
            
            const visibleVolcanoes = visibleRays.filter(volcano => {
                if (
                    volcano.Lat <= lat[0] && volcano.Lat >= lat[1] &&
                    volcano.Lon <= lng[0] && volcano.Lon >= lng[1]
                    ) {
                        return true
                }
                return false
            })
            volcanoesList = visibleVolcanoes.map(v => v.NoVolcan)
        } else {
            if (visibleRays.length > 0){
                volcanoesList = visibleRays.map(v => v.NoVolcan)
            }
        }
        return volcanoesList;
    }

    const handleSubmit = context => async e => {
        e.preventDefault()
        const errors = validate(formData, validations)
        if (errors.length > 0) {
            setFormData({...formData, errors})
        } else {
            const volcanoesList = filterVolcanoes(visibleRays, mapBounds)
            //
            // No se si vamos a hacer que los volcanes se manden por mail o no.
            // const API_URL = '/api/rayos'
            // let url = API_URL+'?accept=text/csv&DateTime[$gt]=' + formData.desde + ' 00:00:00' +
            //                     '&DateTime[$lt]=' + formData.hasta + ' 00:00:00&$limit=9000000'

            // If we have more than 50 volcanoes, we return all of them, because we may hit the
            // max URI length or the server won't make the reply and we'll hit timeout :/
            // if (volcanoesList.length > 0 && volcanoesList.length < 50) {
            //     url = url + '&v=' + volcanoesList.join(',')
            // }

            // req = { status: ok, url: '/storage/....csv', error: 'ERROR...' }
            const desde = formData.desde + ' 00:00:00'
            const hasta = formData.hasta + ' 00:00:00'
            const req = await getDataAsCSV(desde, hasta)
            
            if (req.status == 'ok') {
                // hacer algo con url
                setIsSearching(req.url)
            } else {
                // error 
                setFormData({...formData, errors: [req.error] })
            }
        }
    }
    
    const handleChange = name => e => {
        console.log(name, e.target.value)
        setFormData({...formData, [name]: e.target.value})
    }

    // Each field has a list of tests. Each test is a list of two items:
    // The test itself (as a function) and the error text or a function that returns the error text.
    // No validations - Nombre puede ser vacío
    const validations = {
        desde: [
            [required, 'La fecha "desde" es obligatoria'],
        ],
        hasta: [
            [required, 'La fecha "hasta" es obligatoria'],
        ]
    }


    return(
        <UserContext.Consumer>
        {
          context => { 
            let alert = ''
            if (formData.errors.length > 0) {
                alert = (
                <Alert variant="danger" onClose={() => setFormData({...formData, errors: []})} dismissible>
                  {formData.errors.map((error, i) => <p key={i}>{error}</p>)}
                </Alert>
                )
            }
            const visibleVolcanoes = filterVolcanoes(visibleRays, mapBounds)
            
            return(
            <div>
                {alert}
                <Form onSubmit={handleSubmit(context)}>
                    <Form.Group controlId="formBasicSearch">
                    <Form.Label>Desde</Form.Label>
                    <Form.Control 
                        type="date" 
                        name="desde"
                        onChange={handleChange('desde')}
                    />
                    <Form.Label>Hasta</Form.Label>
                    <Form.Control 
                        type="date" 
                        name="hasta"
                        onChange={handleChange('hasta')}
                    />
                    </Form.Group>

                    <Button variant="primary" type="submit">Buscar</Button>
                    <span style={{marginLeft: "1em", color: "gray"}}>datos de {visibleVolcanoes.length} volcanes</span>
                </Form>
            </div>)}
      }
    </UserContext.Consumer>
    )
}

export default withRouter(SearchComponent);

