import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import client from './lib/feathers'
import _console from './lib/_console'

import NavBar from './components/NavBar/NavBar'
import NavBarSubMenu from './components/NavBar/NavBarSubMenu'
import Mapa from './Pages/Mapa/Mapa'
import Login from './Pages/Login/Login'
import Profile from './Pages/Profile/Profile'
import About  from './Pages/About/About'
import Volcanes from './Pages/Volcanes/Volcanes'
import UserContext from './lib/UserContext'
import getSubscription from './lib/webpush'
import Admin from './Pages/Admin/Admin';
import AdminUsers from './Pages/Admin/AdminUsers/AdminUsers';

import AdminEditUser from './Pages/Admin/AdminEditUser/AdminEditUser'
import NewUserPage   from './Pages/Admin/AdminUsers/New/NewUserPage'

import AdminVolcanes from './Pages/Admin/AdminVolcanes/AdminVolcanes';
import NewVolcanPage from './Pages/Admin/AdminVolcanes/New/NewVolcanPage'
import EditVolcanPage from './Pages/Admin/AdminVolcanes/Edit/EditVolcanPage'


class App extends React.Component {
  constructor(props) {
    super(props)
    const console = _console(false);
    this.state = { user: {}, isLoading: true, errorMessage: '' }
    getSubscription().then(subscription => this.state.user.subscription = subscription)

    // See if we have cached login information
    client.reAuthenticate().then( () => {
      // Add it to the context
      client.get('authentication').then(auth => {
        console.log('Getting auth from cache:', {auth})
        this.setState({user: auth.user, isLoading: false})
      })
    }).catch(e => {
      console.log('Couldnt reauth:', e.message)
      this.setState({isLoading: false})
    })

  }
    // Usuarios
    // * Anónimos: solo pantalla principal. Sin búsqueda (va info que me da Daiana) 
    //             ni avisos ni gráfico.
    // * Registrado: Mapa, nosotros, Perfil.
    // * Admin: Mapa, nosotros, admin de usuario y variables (qué variables son)
    handleRoutesByUser = user => {
      let routes
      if (!user || (user && !user.email)) {
        const links = []  // no extra links for not authenticated users
        routes = (
          <Router>
            <NavBar/>
            <NavBarSubMenu links={links}/>
            <Route path="/"         exact component={About} />
            <Route path="/login"    exact component={Login} />
          </Router>
        )
      } else {
        let links = [ ['/', 'Mapa'], ['/about', 'Acerca de nosotros'] ]
        if (user.isAdmin === 'yes') { links.push(['/admin', 'Admin']) }
        routes = (
          <Router>
            <NavBar/>
            <NavBarSubMenu links={links}/>
            <Switch>
              <Route path="/"         exact component={Mapa} />
              <Route path="/login"    exact component={Login} />
              <Route path="/profile"  exact component={Profile} />
              <Route path="/about"    exact component={About} />
              <Route path="/volcanes" exact component={Volcanes} />
              { user.isAdmin === 'yes' ? <Route path="/admin" exact component={Admin} /> : '' }
              { user.isAdmin === 'yes' ? <Route path="/admin/users" exact component={AdminUsers} /> : '' }
              { user.isAdmin === 'yes' ? <Route path="/admin/users/new" exact component={NewUserPage} /> : '' } // Era AdminNew

              { user.isAdmin === 'yes' ? <Route path="/admin/users/:id" component={AdminEditUser} /> : '' }
              { user.isAdmin === 'yes' ? <Route path="/admin/volcanes" exact component={AdminVolcanes} /> : '' }
              { user.isAdmin === 'yes' ? <Route path="/admin/volcanes/new" exact component={NewVolcanPage} /> : '' }
              { user.isAdmin === 'yes' ? <Route path="/admin/volcanes/:id" component={EditVolcanPage} /> : '' }
            </Switch>
          </Router>
        )
      }
      return routes
    }


  
  render() {
    return (
      <UserContext.Provider 
        value={{authInfo: this.state.user, setAuth: (user,cb) => this.setState({user}, cb)}}>
        { this.state.isLoading ? <p>{this.state.errorMessage}</p> : this.handleRoutesByUser(this.state.user) }
      </UserContext.Provider>
    )
  }
}

export default App;
