import React, {useState} from "react";
import { withRouter } from "react-router"
import UserContext from '../../lib/UserContext'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import {validate} from '../../lib/myForm'
import client from '../../lib/feathers'

const ProfileComponent = ({history}) => {
    const logOut = context => async () => {
        context.setAuth(false, () =>  history.push('/'))
    }

    const [formData, setFormData] = useState({errors: []});

    const doSubmit = async formData => {
        // En algún momento actualizará las preferencias.
    }

    const handleSubmit = context => async e => {
        e.preventDefault()
        const errors = validate(formData, validations)
        if (errors.length > 0) {
            setFormData({...formData, errors})
        } else {
            const me = context.authInfo
            client.service('users').patch(me.id, { nombre: formData.nombre })
            history.push('/')
        }
    }
    
    const handleChange = name => e => {
        console.log(name, e.target.value)
        setFormData({...formData, [name]: e.target.value})
    }

    // Each field has a list of tests. Each test is a list of two items:
    // The test itself (as a function) and the error text or a function that returns the error text.
    // No validations - Nombre puede ser vacío
    const validations = {}


    return(
        <UserContext.Consumer>
        {
          context => { 
            let alert = ''
            if (formData.errors.length > 0) {
                alert = (
                <Alert variant="danger" onClose={() => setFormData({...formData, errors: []})} dismissible>
                  {formData.errors.map((error, i) => <p key={i}>{error}</p>)}
                </Alert>
                )
            }
            
            return(
            <div>
                {alert}
                <Form onSubmit={handleSubmit(context)}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="input" name="nombre" onChange={handleChange('nombre')}
                        placeholder={context.authInfo.nombre || "Su nombre (opcional)"}/>
                    </Form.Group>

              <Button variant="primary" type="submit">Guardar</Button>
              </Form>
              <Button variant="danger" onClick={logOut(context)}>Cerrar Sesión</Button>
            </div>)}
      }
    </UserContext.Consumer>
    )
}

export default withRouter(ProfileComponent);

