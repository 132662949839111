import React from 'react'
import Container from 'react-bootstrap/Container'
import ProfileComponent from './ProfileComponent2'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import 'bootstrap/dist/css/bootstrap.min.css';
import './Profile.css'

const Profile = () => {
    return (
        <Container>
        <Row>
          <Col xs={3}>
          </Col>
          <Col xs={6}>
                <div>
                  <h2>Configuración</h2>
                  <ProfileComponent />
                </div>
          </Col>
          <Col xs={3}>
          </Col>
        </Row>
      </Container>)
}

export default Profile