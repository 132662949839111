import React, {Component} from "react";
import MapComponent from '../../components/MapComponent/MapComponent'
import SearchCard from '../../components/SearchCard/SearchCard'
import MapStats from '../../components/MapStats/MapStats'
import LastChanges from '../../components/LastChanges/LastChanges'
import GraficoContainer from './Grafico/GraficoContainer'
import "./Mapa.css";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import client from '../../lib/feathers'
import _console from '../../lib/_console'
import {getCurrentData, getAvisos} from '../../lib/network'

const log = _console(false)
const debug = 0

class Mapa extends Component {
  constructor() {
    super()
    this.state = {
      allRays: {},
      visibleRays: {},
      statistics: {
        reds: [],
        yellows: [],
        greens: [],
      },
      avisos: [],
      // filter may be renamed to show or showInFilter. 1 means do show.
      filter: {
        red: 1,
        yellow: 1,
        green: 1
      },
      viewport: {
        center: [-31, -70], 
        zoom: 3
      },
      selectedVolcanoInfo: {},
    }
  }

  ////////////////////
  // Helper functions
  ////////////////////
  updateRays = rays => {
    debug && console.log('Setting allRays to', {rays})
    this.setState({
      allRays: rays
    }, this.updateVisibleRays)
  }

  // Update the visibleRays (in case we are supposed to show only the greens, or only the yellows, etc.),
  // so that the map displays only them
  updateVisibleRays = () => {
    let newVisibleRays = Object.values(this.state.allRays).filter(item => {
      if ( item.Cod_Color === 0 && this.state.filter.green === 1 ) {
        return true
      }
      if ( item.Cod_Color === 1 && this.state.filter.yellow === 1 ) {
        return true
      }
      if ( item.Cod_Color === 2 && this.state.filter.red === 1 ) {
        return true
      }
      return false
    })
    this.setState({ visibleRays: newVisibleRays })
  }

  login = async () => {
    try {
      // First try to log in with an existing JWT
      return await client.reAuthenticate()
    } catch (error) {
      console.log('Couldnt reauth:', error)
    }
  }

  onFilterChange = color => {
    return value => {
      const newFilter = { ...this.state.filter, [color]: value }
      this.setState({ filter: newFilter }, this.updateVisibleRays)
      log.log('Changed', color, 'to', value)
    }
  }
  onViewportChanged = mapBounds => {
    this.setState({mapBounds})
  }

  selectVolcano = NoVolcan => {
    this.setState({selectedVolcanoInfo: Object.values(this.state.visibleRays).
                                              filter(item => item.NoVolcan === NoVolcan).
                                              shift()
    })
  }


  componentDidMount() {
    ///////////////////////////////////////
    // Login and get the initial state/data
    ///////////////////////////////////////
    this.login().then( async auth => {
      const avisos = await getAvisos()
      this.setState({avisos: avisos.data})
    })

    debug && console.log('Fetching for the first time...')
    getCurrentData().then(json => this.updateRays(json))

    ////////////////////////////
    // socket.io event handlers.
    ////////////////////////////
    client.service('avisos').on('created', message => {
      debug && console.log('Received aviso', message)
      this.setState( { avisos: [message].concat(this.state.avisos) } )
    })

    client.service('rayosbulk')
      .on('created', message => {
        debug && console.log('Nuevo mensaje de rayosbulk!', {message})

        let raysStateCopy = Object.assign({}, this.state.allRays)
        delete(raysStateCopy._id)

        // We need to update the state
        this.setState({
          allRays: message,
        })
        this.updateVisibleRays()

        // Update statistics for sparkline
        const numReds = Object.keys(message).filter(NoVolcano => message[NoVolcano].Cod_Color === 2 ).length
        const numYellows = Object.keys(message).filter(NoVolcano => message[NoVolcano].Cod_Color === 1 ).length
        const numGreens = Object.keys(message).filter(NoVolcano => message[NoVolcano].Cod_Color === 0 ).length
        this.setState({
          statistics: {
            reds: this.state.statistics.reds.concat([numReds]),
            yellows: this.state.statistics.yellows.concat([numYellows]),
            greens: this.state.statistics.greens.concat([numGreens]),
          },
        })
      });
  }


    render() {
      return(
        <Container fluid={true}>
          <Row>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} >
              <MapComponent rays={this.state.visibleRays} viewport={this.state.viewport}
                onViewportChanged={this.onViewportChanged} selectVolcano={this.selectVolcano}
              />
              {
                this.state.selectedVolcanoInfo.NoVolcan ? (
                  <div style={{paddingTop: "30px"}} >
                <GraficoContainer 
                    selectedVolcanoInfo={this.state.selectedVolcanoInfo}
                />
              </div>
                ) : ''
              }
              
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4} >
              <SearchCard visibleRays={this.state.visibleRays} mapBounds={this.state.mapBounds}/>
              <MapStats rays={this.state.allRays} 
                        statistics={this.state.statistics} 
                        filter={this.state.filter}
                        onFilterChange={this.onFilterChange}
              />
              <LastChanges messages={this.state.avisos}/>
            </Col>
          </Row>
        </Container>
      );
    }
};

export default Mapa;