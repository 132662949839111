import React, {Component} from "react";
import { Map, TileLayer, ZoomControl } from 'react-leaflet'
import { BoxZoomControl } from 'react-leaflet-box-zoom'
import MarkerList from '../../components/MarkerList/MarkerList'
import './MapComponent.css'

class MapComponent extends Component {
    constructor(props) {
      super(props)
      this.state = {
        mapBounds: {},
      }
    }

    // TODO: Fix "datos de xx volcanes"
    onViewportChanged = mapBounds => {
      // this.setState({mapBounds})
      // this.props.onViewportChanged(mapBounds)
    }

  

      render() {
        return(
          <Map ref='map' 
            animate={true} 
            viewport={this.props.viewport} 
            zoomControl={false} 
            onViewportChanged={() => {
              const mapBounds = this.refs.map.leafletElement.getBounds()
              this.onViewportChanged(mapBounds)
            }}
          >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ZoomControl position="topright" />
            <BoxZoomControl 
              position="topright"
              sticky={false}
            />
            <MarkerList mapBounds={this.state.mapBounds} rays={this.props.rays} selectVolcano={this.props.selectVolcano}/>
        </Map>
        )}

    };

    export default MapComponent;
