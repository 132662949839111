import React, {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ReactLoading from 'react-loading';
import { urlExists } from '../../lib/network'

import SearchComponent from './SearchComponent'
import './SearchCard.css'

const checkIfExists = async (url, setIsSearching) => {
    const exists = await urlExists(url)
    console.log({exists})
    if (exists) {
        console.log('Existe! 1')
        window.location = url
        console.log('existe! 2')
        setIsSearching(false)
        console.log('existe! 3')
    }
}

const Searching = ({isSearching, setIsSearching}) => {
    useEffect( () => {
        const timer = setInterval( () => checkIfExists(isSearching, setIsSearching), 3000)
        return () => clearInterval(timer)
    })

    return (
        <div>
            <ReactLoading type="spin" color="blue" width={'50%'} height={'100%'} className="overlay-loading" />
            <Button className="cancel-search-button" variant="primary" type="submit" onClick={() => setIsSearching(false)}>Cancelar</Button>
        </div>
    )
}

const SearchCard = (props) => {
    const [isSearching, setIsSearching] = useState(false)

    return (
        <Card>
            <Card.Header>Buscar</Card.Header>
            <Card.Body>
                {
                    isSearching ? 
                                <Searching setIsSearching={setIsSearching} isSearching={isSearching} /> : 
                                <SearchComponent {...props} setIsSearching={setIsSearching} />
                }
            </Card.Body>
        </Card>
    )
}

export default SearchCard;