import React, {Component} from 'react'
import { Line } from 'react-chartjs-2'
import * as zoom from 'chartjs-plugin-zoom'
import 'chartjs-adapter-date-fns';
import {es} from 'date-fns/locale';


// https://stackoverflow.com/questions/59734992/using-epoch-as-time-series-on-x-axis-in-react-chartjs-2-not-showing-correct-date
// https://github.com/chartjs/chartjs-plugin-zoom/issues/64
// https://github.com/chartjs/chartjs-plugin-zoom
// Time format (for the x Axis):
// https://www.chartjs.org/docs/latest/axes/cartesian/time.html
//
// Alternativas:
// https://visjs.org/
// https://gionkunz.github.io/chartist-js/examples.html#example-line-svg-animation
// https://medium.com/@mtiller/open-source-plotting-libraries-primarily-for-react-c43cfa4dc90f


// Props:
//      * data:  the data (list of objects)
//      * label: e.g. volcano XXXXX-XX
//      * title: The graph's color
const Chart = ({data, label, title}) => {  
    const chartData = {
        labels: data.map(entry => new Date(entry.DateTime).getTime()),
        datasets: [{
            label: label,
            // In order to make some padding, we make 5 categories: empty, green, yellow, red and empty.
            // Thus, we need to make 0 -> 1, 1 -> 2 and 2 -> 3
            data: data.map(entry => entry.Cod_Color + 1),
            // borderColor: the Line Color.
            // borderColor: ctx => { 
            //     return ctx.dataset.data[ctx.dataIndex] === 1 ? '#00FF00' :
            //            ctx.dataset.data[ctx.dataIndex] === 2 ? '#FFFF00' :
            //            ctx.dataset.data[ctx.dataIndex] === 3 ? '#FF0000' :
            //            '#0000FF'
            // },
            // backgroundColor: the area color
            backgroundColor:'rgb(0, 0, 0, 0.05', // green, almost transparent
            lineTension: 0,
            // https://www.chartjs.org/docs/latest/configuration/elements.html#point-styles
            pointBackgroundColor: ctx => { 
                return ctx.dataset.data[ctx.dataIndex] === 1 ? '#00FF00' :
                    ctx.dataset.data[ctx.dataIndex] === 2 ? '#FFFF00' :
                    ctx.dataset.data[ctx.dataIndex] === 3 ? '#FF0000' :
                    '#0000FF'
            },
            radius: 6,   // the point's radius
        }]
    }

    const getRayosInOutforIndex = idx => [ data[idx].Rayos_in, data[idx].Rayos_out]

    return (
        <div className="chart">
        <Line
            data={chartData}
            height={300}
            options={{
                tooltips: {
                    enabled: true,
                    callbacks: {
                        // Return the amount of rays in the tooltip
                        label: (tooltipItem, data) => { 
                            const rayos = getRayosInOutforIndex(tooltipItem.index)
                            return `Rayos Internos: ${rayos[0]} Rayos externos: ${rayos[1]}`
                        }
                    }
                },
                layout: {
                    borderColor: '#FF0000',
                    borderWidth: 10,
                },
                scales: {
                    yAxes: [{
                        // category means that it has fixed values
                        type: 'category',
                        labels: ['', 'verde', 'amarillo', 'rojo', ''],
                        ticks: {
                            reverse: true,   // important, please don't change
                        }
                    }],
                    xAxes: [{ 
                        adapters: {
                            date: {
                                locale: es
                            }
                        },
                        type: 'time',
                        time: {
                            // We want the month and day in the x Axis
                            displayFormats: {
                                minute: 'MMM d - h:mm a',
                                hour: 'MMM d - ha',
                            },
                        }
                    }]
                },
                maintainAspectRatio: false,
                title: {
                    display: !!title,
                    text: title,
                    fontSize: 25,
                },
                legend: {
                    display: !!title,
                    position: 'right'
                },
                pan: {
                    enabled: false, // conflicts with zoom
                    mode: 'x',
                },
                zoom: {
                    enabled: true,                      
                    mode: 'x',
                    drag: true,
                },
            }}
        />
        </div>

    )
}

export default Chart