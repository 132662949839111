import {required, isANumber, isBetween} from '../../../../lib/myForm'
// Each field has a list of tests. Each test is a list of two items:
// The test itself (as a function) and the error text or a function that returns the error text.
export const validations = {
    NoVolcan: [
        [required, 'Por favor elija un codigo de volcan'],
        [isBetween(2,8),  'El codigo de volcan debe tener entre 2 y 8 caracteres']
    ],
    Nombre: [ [required, 'El nombre del volcan es obligatorio'] ],        
    Region: [ [required, 'La region del volcan es obligatoria'] ],        
    Type:   [ [required, 'El tipo del volcan es obligatorio']   ],        
    Lat: [
        [required,  'La latitud es obligatoria'],
        [isANumber, 'La latitud debe ser un numero'],
    ],  
    Lon: [
        [required,  'La longitud es obligatoria'],
        [isANumber, 'La longitud debe ser un numero'],
    ],
}