import React from 'react';
import ChartLogic from './ChartLogic'
import Card from 'react-bootstrap/Card'

const GraficoContainer = ({selectedVolcanoInfo}) => {
    console.log( 'GraficoContainer', {selectedVolcanoInfo})
    return (
        <Card>
            <Card.Header>{selectedVolcanoInfo.Nombre} - estado ultimos 7 dias</Card.Header>
            <Card.Body>
                <ChartLogic NoVolcan={selectedVolcanoInfo.NoVolcan}/>
            </Card.Body>
        </Card>
    )
}

export default GraficoContainer;