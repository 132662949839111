import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export const required = string => string !== undefined && string !== '' && string !== null 
export const isEmail = string => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(string)
export const isBetween = (min,max) => string => string !== undefined && string.length >= min && string.length <= max
export const isEqualToField = field => (string, formData) => string !== undefined && string === formData[field]
export const isANumber = string => /^[0-9.-]+$/i.test(string)

export const validate = (formData, validations) => {
    console.log({validations, formData})
    let errors = []
    Object.keys(validations).forEach(field => {
        let newError;
        validations[field].forEach(test => {
            //console.log('hmmm', test)
            if (! newError &&  ! test[0](formData[field], formData)) {
                console.log({newError})
                if (typeof(test[1]) === 'function') {
                    newError = test[1](formData[field], formData)
                } else {
                    newError = test[1]
                }
                errors.push(newError)
            }
        })

    })
    return errors
}

const _createFormElement = formElement => {
    //console.log('_createFormElement: got', formElement)
    if (formElement[2] === 'input') {
        return (
            <Form.Group key={formElement[0]} controlId={formElement[0]}>
                <Form.Label>{formElement[1] || formElement[0]}</Form.Label>
                <Form.Control type="input" 
                    name={formElement[0]} 
                    onChange={formElement[3]} 
                    placeholder={formElement[4]}
                    value={formElement[5]}
                />
            </Form.Group>
        )
    }
    if (formElement[2] === 'checkbox') {
        return (
            <Form.Group key={formElement[0]} controlId={formElement[0]}>
                <Form.Check type="checkbox" 
                    onChange={formElement[3]} 
                    label={formElement[1]} 
                    checked={formElement[5]}
                />
            </Form.Group>
        )
    }
    if (formElement[2] === 'password') {
        return (                    
            <Form.Group key={formElement[0]} controlId={formElement[0]}>
                <Form.Label>{formElement[1] || formElement[0]}</Form.Label>
                <Form.Control 
                    type="password" 
                    name="password"
                    onChange={formElement[3]}
                    placeholder={formElement[4]}
                />
            </Form.Group>
        )
    }

    if (formElement[2] === 'submit') {
        return (
            <Button key="button" variant="primary" type="submit">{formElement[1]}</Button>
        )
    }

    return <p>Funny element {formElement[2]}</p>
}

export const createFormFromSchema = schema => {    
    // A button of type submit must have the form's onSubmit
    let onSubmit = () => console.log('No submit button? See myForm.js')
    const submitButton = schema.filter(formElement => formElement[2] === 'submit')
    if (submitButton.length === 1) {
        onSubmit = submitButton[0][3]
        //console.log({onSubmit})
    }
    
    return (
        <Form onSubmit={onSubmit}>
            { schema.map(formElement => _createFormElement(formElement)) }
        </Form>
    )
}