import React from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import './RayMarker.css'

const RayMarker = ({Lat, Lon, Cod_Color, Nombre, Rayos_in, Rayos_out, mapBounds, NoVolcan, selectVolcano, link}) => {
    let iconSize =  [20, 20]
    if (mapBounds._northEast) {
        const latDiff = Math.abs(mapBounds._northEast.lat - mapBounds._southWest.lat)
        if (latDiff < 30) {
            iconSize = [40, 40]
        }
        if (latDiff < 15) {
            iconSize = [60, 60]
        }
    }
    
    const redIcon    = L.icon({iconUrl: '/volcano_red.png', iconSize })
    const yellowIcon = L.icon({iconUrl: '/volcano_yellow.png', iconSize })
    const greenIcon  = L.icon({iconUrl: '/volcano_green.png', iconSize })

    let myIcon = greenIcon
    let imgUrl = '/volcano_green.png'
    if (Cod_Color === 1) {
        myIcon = yellowIcon
        imgUrl = '/volcano_yellow.png'
    }
    if (Cod_Color === 2) {
        myIcon = redIcon
        imgUrl = '/volcano_red.png'
    }

    return (
        <Marker position={[Lat,Lon]} icon={myIcon} onClick={() => {
            selectVolcano(NoVolcan)
        }}>
        <Popup>
            <div className="popup">
                <img src={imgUrl} />
                <div className="popup-descr">
                    <h3>{Nombre}</h3>
                    <ul>
                        <li>Rayos internos: {Rayos_in}</li>
                        <li>Rayos externos: {Rayos_out}</li>
                        {
                            link ? (<li><a target="_blank" href={link}>Mas informacion</a></li>) : ''
                        }
                        
                    </ul>
                </div>
            
            </div>
            
        </Popup>
    </Marker>
    )
}

export default RayMarker;