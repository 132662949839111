import client from './feathers'

// getHistoricalDataFromVolcano: gets data from a volcano to make a graph
export const getHistoricalDataFromVolcano = async NoVolcan => {
    const debug = 0
    const lastWeek = new Date()
    lastWeek.setDate( lastWeek.getDate() - 7 )
    const rawData = await client.service('rayos').find({
        query: {
          $limit: 999999,
          $select: [ 'DateTime', 'Cod_Color', 'Rayos_in', 'Rayos_out' ],
          NoVolcan: NoVolcan,
          $sort: { DateTime: -1 },
          DateTime: {
            $gt: lastWeek.toISOString().substring(0,10) // e.g. '2020-02-27'
          },
        }
    }).catch(e => console.log({e})) // Maybe timeout?
    debug && console.log('getHistoricalDataFromVolcano', {rawData})

    // Filter duplicate times (sometimes there are)
    let seen = {}
    const data = rawData.data.filter(entry => {
        if (seen[entry.DateTime] === 1) {
            return false
        } else {
            seen[entry.DateTime] = 1
            return true
        }
    })
    // Return just the data ( a list of objects )
    return data
}

// getCurrentData: get (via rest) the data. Returns a Promise.
export const getCurrentData = () => {
    return fetch('/api/rayosbulk')
    .then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}

// getAvisos: va a buscar los avisos
export const getAvisos = () => {
    return fetch('/api/avisos?$limit=10&$sort[DateTime]=-1')
    .then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}
// TODO: Que volcanes bajar
export const getDataAsCSV = async (desde, hasta) => {
    const debug = 1
    debug && console.log("getDataAsCSV:", {desde, hasta})
    const req = await client.service('rayos').find({
        query: {
          $limit: 999999,
          accept: 'text/csv',
          DateTime: {
            $gt: desde,
            $lt: hasta,
          },
        }
    }).catch(e => console.log({e})) // Maybe timeout?
    debug && console.log('getDataAsCSV', {req})
    return req
} 

// urlExists: Revisa (via HEAD) si una URL esta disponible
export const urlExists = async (url) => {
    const debug = 0
    const res = await fetch(url, {method: 'HEAD'})
    debug && console.log('urlExists:' , {res})
    if (res.status === 200) {
        return true
    }
    return false
}
