import React from "react";
import "./NavBar.css";

const NavBar = props => {
  return (
    <div>
      <nav className="navbar-logos">
        <div>
          <img alt="georayos" src="/georayos_logo.png" />
          <img alt="georayos" src="/volcano_transp.png" />
        </div>
        <div>
          <img alt="atmosfera" src="/logo_atmosfera.png" />
          <img alt="citedef" src="/citedef_logo.png" />
          <img alt="mindef" src="/mindef_logo.png" />
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
