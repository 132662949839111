import React, {useEffect, useState} from 'react'
import Chart from './Chart'
import { getHistoricalDataFromVolcano } from '../../../lib/network'

// ChartLogic busca los datos y devuelve el grafico solo cuando esten
const ChartLogic = ({NoVolcan}) => {
    const debug = 1
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect( () => {
        const getEstadoUltimaSemana = async (NoVolcan) => {
            const rawData = await getHistoricalDataFromVolcano(NoVolcan)
            console.log({rawData})
            setData(rawData)
        }
        getEstadoUltimaSemana(NoVolcan)
        setIsLoading(false)
    }, [NoVolcan])
    debug && console.log({data})
    return (
        isLoading ? 
            'Loading...' :
            <Chart label={false} title={false} data={data} /> // No quiero title y label por ahora
    )
}

export default ChartLogic