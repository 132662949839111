import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom'
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import TableComponent from '../TableComponent'
import client from '../../../lib/feathers'
import "./AdminUsers.css";



const AdminUsers = ({history}) => {
    const [users, setUsers] = useState({data: []})
    const [alert, setAlert] = useState('')

    const getUsers = async () => {
        console.log('Getting users...')
        const usersService = client.service('users')

        const usersList = await usersService.find({
            query: { $limit: 9999 }
          })
        console.log('Got users:', usersList)
        setUsers({data: usersList.data})
    }

    useEffect( () => {
        getUsers()
    }, [])
    
    const columns = [{ dataField: 'id',      text: 'id',    style: { width: "2rem"}, headerStyle: { width: '2rem' }, },
                     { dataField: 'nombre',  text: 'Nombre' },
                     { dataField: 'email',   text: 'Email'  },
                     { dataField: 'isAdmin', text: 'Admin?', style: { width: "5rem"}, headerStyle: { width: '5rem' } },    
                    { 
                        isDummyField: true,   
                        text: '',       
                        style: { width: "4rem"}, 
                        headerStyle: { width: '4rem' },
                        align: 'right',
                        formatter: (cellContent, row) => (<div>
                            <span className="fa fa-pencil table-icon" onClick={() => history.push('/admin/users/' + row.id)}></span>
                            <span className="fa fa-trash table-icon"onClick={() => confirmDelete(row.id)}></span>
                        </div>) 
                    }                 
                    ]
    const indication = () => {
        return 'Tabla vacia'
    }
    
    const confirmDelete = id => {
        setAlert(
            <Alert variant="danger" onClose={() => setAlert('')} dismissible>
                ¿Está seguro/a de que quiere borrar al usuario {id}?
                <Button className="button-confirmar-borrar-usuario" variant="danger" onClick={() => doDeleteUser(id)}>Confirmar</Button>
            </Alert>
        )
    }

    const doDeleteUser = async (id) => {
        console.log('Deleting userId', id, 'after confirmation')
        try {
            await client.service('users').remove(id)
            setAlert('')
        } catch(e) {
            console.log(e)
            setAlert(
                <Alert variant="danger" onClose={() => setAlert('')} dismissible>
                {e.message}
                </Alert>
            )
        }
        getUsers()
    }

    return (
        <Container>
            <h1>Administrar usuarios</h1>
            {alert}
            <Button className="table-new-button" 
                    onClick={() => history.push('/admin/users/new')}>
                    Crear Nuevo
                </Button>
            <Row>
            <Col>
            {
                users.data.length > 0 ?
                    <TableComponent 
                        data={ users.data } 
                        columns={ columns } 
                        noDataIndication={ indication }
                        keyField='id' 
                        pagination={ paginationFactory() }
                    /> : <p>Loading...</p>
            }
            </Col>
            </Row>
        </Container>
    )
};

export default withRouter(AdminUsers);
