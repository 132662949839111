import React, {useEffect, useState} from 'react';
import SparklineComponent from './SparklineComponent'
import Switch from 'react-input-switch';
import './MapStats.css'
import Card from 'react-bootstrap/Card'


const MapStats = ({rays, statistics, filter, onFilterChange}) => {
    const [lastUpdated, setLastUpdated] = useState('Cargando...')
    const vList = Object.keys(rays)

    const debug = 0
    debug && console.log('MapStats called')
    debug && console.log({rays})
    debug && console.log({vList})
    debug && console.log(vList.length)
    debug && vList.length > 0 && console.log('vList tiene algo')
    debug && vList.length > 0  && console.log(rays[vList[0]].DateTime)

    const diffDates = lastUpdated => {
        const now = new Date().getTime()
        const diff = Math.floor( (now - lastUpdated) / 1000 )
        const min = parseInt(diff / 60) 
        const sec = diff % 60
        let msg = 'datos de hace'
        if (min > 0 ) { msg = msg + ' ' + min + 'min' }
        if (sec > 0 ) { msg = msg + ' ' + sec + 's' }
        return msg
    }

    // Last Updated: every second it should update the age of the data
    useEffect( () => {
        let interval
        if (vList.length > 0) {
            const fechaISO =  rays[vList[0]].DateTime // 2020-04-22 17:08:00
            const date = new Date(fechaISO + 'Z')     // a Date object. Z means UTC
            interval = setInterval( () => {
                const diff = diffDates(date.getTime())
                debug && console.log('This every 1000ms:', diff)
                setLastUpdated(diff)
            }, 1000)
        }
        return () => clearInterval(interval)
    }, [vList])

    // Sparklines
    debug && console.log({statistics})

    // Current reds, yellows and greens. The ones for sparkline come in statistics props.
    const numReds = vList.filter(NoVolcano => rays[NoVolcano].Cod_Color === 2 ).length
    const numYellows = vList.filter(NoVolcano => rays[NoVolcano].Cod_Color === 1 ).length
    const numGreens = vList.filter(NoVolcano => rays[NoVolcano].Cod_Color === 0 ).length
    debug && console.log({numReds, numYellows, numGreens})


    return (
        <Card>
            <Card.Header>Estadísticas 
                <span className="lastUpdate-stats">
                    ({lastUpdated})
                </span></Card.Header>
            <Card.Body>
                <p>
                   <Switch value={filter.red} onChange={onFilterChange('red')} className="switch-stats"/>
                    {numReds}    rojo     <SparklineComponent past={statistics.reds} color="red" />
                </p>
                <p>
                    <Switch value={filter.yellow} onChange={onFilterChange('yellow')} className="switch-stats"/>
                    {numYellows} amarillo <SparklineComponent past={statistics.yellows} color="yellow" />
                </p>
                <p>
                    <Switch value={filter.green} onChange={onFilterChange('green')} className="switch-stats"/>
                    {numGreens}  verde    <SparklineComponent past={statistics.greens} color="green" />
                </p> 
            </Card.Body>
            
        </Card>
    )
}

export default MapStats;