import React from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NewVolcanComponent from './NewVolcanComponent'
import 'bootstrap/dist/css/bootstrap.min.css';

const NewVolcanPage = () => {
  return (
    <Container>
      <Row>
        <Col xs={2}>
        </Col>
        <Col xs={8}>
            <NewVolcanComponent />
        </Col>
        <Col xs={2}>
        </Col>
      </Row>
    </Container>

  );
};

export default NewVolcanPage;
