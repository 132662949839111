import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MapComponent from '../../components/MapComponent/MapComponent'
import GeneralInfo from './GeneralInfo'
import {getCurrentData} from '../../lib/network'

import 'bootstrap/dist/css/bootstrap.min.css';
import './About.css'
import Card from 'react-bootstrap/Card'

const Leyend = () => {
  return (
    <div className="grid-container">
      <img style={{width: "4rem"}} src="/volcano_red.png"></img>
      <p>Descargas eléctricas detectadas, tienen mayor probabilidad de provenir de una erupción volcánica explosiva.</p>
      <img style={{width: "4rem"}} src="/volcano_yellow.png"></img>
      <p>Descargas eléctricas detectadas, son probablemente provenientes de una tormenta eléctrica meteorológica cercana.</p>
      <img style={{width: "4rem"}} src="/volcano_green.png"></img>
      <p>Descargas eléctricas detectadas,tienen mayor probabilidad de provenir de una tormenta eléctrica meteorológica, 
         o no hay descargas eléctricas.</p>
    </div>
  )
}

const About = () => {
  const [v, setV] = useState([])
  const viewport = {
    center: [-31, -70], 
    zoom: 3
  }

  useEffect( () => {
    getCurrentData().then(json => setV(json))
  }, [])

    return (
      <Container fluid={true}>
        <Row>
        {/* xs, sm, md, large, and xl) */}
          <Col xs={12} sm={12} md={8} lg={8} xl={8} >
                  <div>
                    <GeneralInfo />
                  </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} >
            <MapComponent rays={v} viewport={viewport} selectVolcano={() => {}}/>
            <Leyend />
          </Col>
        </Row>
      </Container>
    )
}

export default About