import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import 'bootstrap/dist/css/bootstrap.min.css';
import client from '../../lib/feathers'
import {required, isEmail, isBetween, isEqualToField, validate} from '../../lib/myForm'

const AdminEditUserComponent = () => {
    const [formData, setFormData] = useState({errors: [], success: []});
    
    // Need a state for every field if I want to give it value={}. 
    // Else, react will complain about controlled and uncontrolled components
    const [nombre , setNombre]    = useState('')
    const [email , setEmail]      = useState('')
    const [isAdmin , setIsAdmin]  = useState(false)

    const { id } = useParams()
    useEffect( () => {
        const getUserData = async () => {
            const userData = await client.service('users').get(id)
            console.log({userData})
            setNombre(userData.nombre || '')
            setEmail(userData.email || '')
            setIsAdmin(userData.isAdmin === 'yes' ? true : false)
        }
        getUserData()
    }, [])

    const handleSubmit = async e => {
        e.preventDefault()
        const errors = validate({nombre, email, isAdmin}, validations)
        if (errors.length > 0) {
            setFormData({errors, success: []})
        } else {
            // OK!
            console.log('Validation ok for', {nombre, email, isAdmin})
            let isAdminYN = 'no'
            if (isAdmin) {
                isAdminYN = 'yes'
            }
            try {
                console.log({isAdminYN})
                await client.service('users').patch(id, { nombre, email, isAdmin: isAdminYN })
                setFormData({success: ['Usuario actualizado'], errors: []})
            } catch(e) {
                console.log('Error while registering:', e)
                setFormData({errors: ['Error al actualizar el nuevo usuario'], success: []})
            }
        }
    }

    const handleChange = updateState => e => {
        console.log(e.target.value)
        updateState(e.target.value)
    }

    const handleCheckbox = e => {
        console.log({isAdmin})
        setIsAdmin(!isAdmin)
    }

    // Each field has a list of tests. Each test is a list of two items:
    // The test itself (as a function) and the error text or a function that returns the error text.
    const validations = {
        email: [
            [required, 'La dirección de correo es obligatoria'],
            [isEmail,  string => string + ' no parece una dirección de correo']
        ],
    }

    let alert = ''
    if (formData.errors && formData.errors.length > 0) {
        alert = (
        <Alert variant="danger" onClose={() => setFormData({...formData, errors: []})} dismissible>
          {formData.errors.map((error, i) => <p key={i}>{error}</p>)}
        </Alert>
        )
    }
    let success = ''
    if (formData.success && formData.success.length > 0) {
        success = (
            <Alert variant="success" onClick={() => setFormData({...formData, success: []})} dismissible>
                {formData.success.map((success, i) => <p key={i}>{success}</p>)}
            </Alert>
        )
    }

    return(
        <div>
            {alert}{success}
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formNewUserName">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="input" name="nombre" value={nombre} onChange={handleChange(setNombre)} placeholder="(opcional)"/>
                </Form.Group>
                <Form.Group>    
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        id="email" 
                        type="input" 
                        name="email" 
                        value={email}                        
                        onChange={handleChange(setEmail)}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control 
                        id="password"
                        type="password" 
                        name="password"
                        placeholder="Contraseña" />
                    <Form.Control 
                        id="password2"
                        type="password" 
                        name="password2"
                        placeholder="Contraseña (de nuevo)" />
                </Form.Group>

                <Form.Group>
                    <Form.Check type="checkbox" checked={isAdmin} onChange={handleCheckbox} label="¿Es administrador?"/>
                </Form.Group>
          <Button variant="primary" type="submit">Actualizar usuario</Button>
          </Form>
        </div>
    )
}

export default AdminEditUserComponent