import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import 'bootstrap/dist/css/bootstrap.min.css';
import client from '../../../../lib/feathers'
import { validate, createFormFromSchema } from '../../../../lib/myForm'
import { validations } from './validations'

const NewUserComponent = () => {
    const [formData, setFormData] = useState({errors: [], success: []});

    const handleSubmit = async e => {
        e.preventDefault()
        const errors = validate(formData, validations)
        if (errors.length > 0) {
            setFormData({...formData, errors})
        } else {
            // OK!
            console.log('Validation ok for', formData)
            const { nombre, email, password } = formData
            const isAdmin = formData.isAdmin === 'on' ? 'yes' : 'no'
            try {
                await client.service('users').create({ nombre, email, password, isAdmin })
                setFormData({...formData, success: ['Nuevo usuario ' + formData.email + ' creado'], errors: []})
            } catch(e) {
                console.log('Error while registering:', e)
                setFormData({...formData, errors: ['Error al crear el nuevo usuario']})
            }
        }
    }

    const handleChange = name => e => {
        console.log(name, e.target.value)
        setFormData({...formData, [name]: e.target.value})
    }

    let alert = ''
    if (formData.errors && formData.errors.length > 0) {
        alert = (
        <Alert variant="danger" onClose={() => setFormData({...formData, errors: []})} dismissible>
          {formData.errors.map((error, i) => <p key={i}>{error}</p>)}
        </Alert>
        )
    }
    let success = ''
    if (formData.success && formData.success.length > 0) {
        success = (
            <Alert variant="success" onClick={() => setFormData({...formData, success: []})} dismissible>
                {formData.success.map((success, i) => <p key={i}>{success}</p>)}
            </Alert>
        )
    }

    // name , label, type, onChange, placeholder
    const formSchema = [
        ['nombre',   'Nombre',            'input',     handleChange('nombre'),   ''],
        ['email',    'Direccion de Email',             'input',     handleChange('email'),    ''],
        ['password', 'Contraseña',        'password',  handleChange('password'),     ''],
        ['password2', 'Contraseña (de nuevo)',          'password',  handleChange('password2'),      ''],
        ['enabled',  'Es Administrador?', 'checkbox',  handleChange('isAdmin')             ],
        ['',         'Crear Usuario',     'submit',    handleSubmit                        ],
    ]
 
    return(
        <div>
            {alert}{success}
            {createFormFromSchema(formSchema)}
        </div>
    )
}

export default NewUserComponent