import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import './VolcanesList.css'

const VolcanesList = () => {
    return (
        <p>Lista</p>)
}

export default VolcanesList