import React from "react";
import { Link } from 'react-router-dom'
import UserContext from '../../lib/UserContext'
import "./NavBar.css";

const NavBarSubMenu = props => {
  return (
    <UserContext.Consumer>
      {
        context => { 
          console.log(context)
          return(
            <div>
              <nav className="navbar-navigation" role="navigation">
                    <ul>
                        {
                        props.links.map( link => <li key={link[0]}><Link to={link[0]}>{link[1]}</Link></li>)
                        }
                        <a href="/api/avisos?accept=application/xml">CAP</a>
                    </ul>
                    {
                        context.authInfo.email ? 
                            <Link to="/profile">{context.authInfo.nombre || context.authInfo.email}</Link> :
                            <Link to="/login">Iniciar Sesión</Link> 
                    }
              </nav>
            </div>

        )}
      }
    </UserContext.Consumer>
  );
}

export default NavBarSubMenu;
