import { required, isEmail, isBetween, isEqualToField } from '../../../../lib/myForm'

// Each field has a list of tests. Each test is a list of two items:
// The test itself (as a function) and the error text or a function that returns the error text.
export const validations = {
    email: [
        [required, 'La dirección de correo es obligatoria'],
        [isEmail,  string => string + ' no parece una dirección de correo']
    ],
    password: [
        [required, 'La contraseña es obligatoria'],
        [isBetween(2,60),  'La contraseña debe tener entre 2 y 60 caracteres']
    ],
    password2: [
        [required, 'La segunda contraseña es obligatoria'],
        [isEqualToField('password'),  'Las dos contraseñas deben ser iguales']
    ]
}