import React from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AdminEditUserComponent from '../AdminEditUserComponent'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./AdminEditUser.css";

const AdminEditUser = () => {
  return (
    <Container>
      <Row>
        <Col xs={3}>
        </Col>
        <Col xs={6}>
            <AdminEditUserComponent />
        </Col>
        <Col xs={3}>
        </Col>
      </Row>
    </Container>

  );
};

export default AdminEditUser;
