import React from 'react';
import RayMarker from '../RayMarker/RayMarker';

const MarkerList = ({rays, mapBounds, selectVolcano}) => {
    const markerArray = Object.values(rays).map((ray,i) => {
        if (! ray.Lat) { console.log('MarkerList: ray without coords?', ray); return ''}
        return <RayMarker key={ray.NoVolcan} Lat={ray.Lat} Lon={ray.Lon} 
                            Cod_Color={ray.Cod_Color} Nombre={ray.Nombre}
                            Rayos_in={ray.Rayos_in} Rayos_out={ray.Rayos_out}  
                            mapBounds={mapBounds}   NoVolcan={ray.NoVolcan} 
                            link={ray.link}
                            selectVolcano={selectVolcano}      
                />
    })
    return (<div>{markerArray}</div>)
}

export default MarkerList;