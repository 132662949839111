import React, { useState } from "react";
import LoginComponent from './LoginComponent2'
// import RegisterComponent from './RegisterComponent2'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import 'bootstrap/dist/css/bootstrap.min.css';
import "./Login.css";

const Login = () => {
  const [action, setAction] = useState('login');
  return (
    <Container>
      <Row>
        <Col xs={3}>
        </Col>
        <Col xs={6}>
              <div>
                <h2>Iniciar sesión  
                  <div className="login-form-subtitle" 
                      onClick={() => setAction('register')} ></div>
                </h2>
                <LoginComponent />
              </div>
        </Col>
        <Col xs={3}>
        </Col>
      </Row>
    </Container>

  );
};

export default Login;
